import { Controller } from "stimulus";
import Tagify from "@yaireo/tagify";

export default class extends Controller {
  private tagify: Tagify;

  connect(): void {
    let whitelisttags: string[] = [];
    if (
      this.element instanceof HTMLElement &&
      this.element.dataset.whitelisttags
    ) {
      whitelisttags = this.element.dataset.whitelisttags.split(",");
    }

    this.tagify = new Tagify(this.element as HTMLElement, {
      delimiters: ",| ",
      whitelist: whitelisttags,
      enforceWhitelist: whitelisttags.length > 0,
      originalInputValueFormat: (valuesArr) =>
        valuesArr.map((item) => item.value).join(","),
      templates: {
        tag: function (tagData) {
          return `<tag title="${tagData.value}" contenteditable='false' spellcheck='false' class='tagify__tag tagify--noAnim'>
                      <x title='remove tag' role='button' aria-label='remove tag'></x>
                      <div>#${tagData.value}</div>
                  </tag>`;
        },
      },
    });
  }
}
