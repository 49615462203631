export const registerControllers = (application, controllers) => {
  for (const path in controllers) {
    const controller = controllers[path].default;
    if (typeof controller === "function") {
      // ../../components または ../stimulus/ で始まるパスから、その部分を除いた残りを使用
      const basePath = path.replace(
        /^(?:\.\.\/\.\.\/components\/|\.\.\/stimulus\/)/,
        "",
      );

      const controllerName = basePath
        .replace(/_controller\.[tj]s$/, "") // _controller.ts または .js を削除
        .replace(/\//g, "--") // スラッシュをダブルハイフンに変換
        .replace(/_/g, "-"); // アンダースコアをハイフンに変換

      //console.log(path);
      //console.log(controllerName, controller);
      application.register(controllerName, controller);
    }
  }
};
