import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkboxAll", "checkbox"];

  connect() {
    this.checkboxAllTarget.addEventListener("change", this.toggle.bind(this));
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.addEventListener("change", this.refresh.bind(this)),
    );

    this.refresh();
  }

  disconnect() {
    this.checkboxAllTarget.removeEventListener(
      "change",
      this.toggle.bind(this),
    );
    this.checkboxTargets.forEach((checkbox) =>
      checkbox.removeEventListener("change", this.refresh.bind(this)),
    );
  }

  toggle() {
    const allChecked = this.checkboxAllTarget.checked;
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = allChecked;
      checkbox.dispatchEvent(new Event("input"));
    });
    this.refresh();
  }

  refresh() {
    const totalCheckboxes = this.checkboxTargets.length;
    const checkedCount = this.checked.length;

    this.checkboxAllTarget.checked = checkedCount === totalCheckboxes;
    this.checkboxAllTarget.indeterminate =
      checkedCount > 0 && checkedCount < totalCheckboxes;
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked);
  }
}
