import Flatpickr from "stimulus-flatpickr";
import { Japanese } from "flatpickr/dist/l10n/ja.js";

export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Japanese,
    };
  }
}
